.swagger-ui {
  background: white;
  padding: 10px 0 10px 0;
  border-radius: 5px;
}

.swagger-ui .scheme-container {
  background: white;
}

.swagger-ui select {
  background: white
    url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13.418 7.859a.695.695 0 0 1 .978 0 .68.68 0 0 1 0 .969l-3.908 3.83a.697.697 0 0 1-.979 0l-3.908-3.83a.68.68 0 0 1 0-.969.695.695 0 0 1 .978 0L10 11l3.418-3.141z"/></svg>')
    right 10px center no-repeat;
}

.swagger-ui .info {
  margin: 10px 0;
}

.swagger-ui .info hgroup.main {
  margin: 0 0 10px;
}

.swagger-ui .info hgroup.main a {
  display: none;
}
